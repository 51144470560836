import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"

const HostedVoipConnectivity = ({ data }) => (
  <Layout>
    <Seo
      title="Hosted VoIP and Connectivity"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Hosted VoIP and Connectivity</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Communications are a fundamental component of your business
              operations and using the technology to maximize operations, costs
              and maintenance.
            </p>
            <p>
              We work with only the best partners to provide you the best
              service. VoIP, or Voice over Internet Phones, provides your
              business the ability to transmit phone calls over the internet
              instead of using a circuit telephone network. This will allow your
              company to link one phone number to multiple devices.
            </p>
            <h4>Benefits of VoIP:</h4>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>Voice, fax, text, and conferencing</li>
                  <li>No PBX hardware, setup or activation fees</li>
                  <li>Unlimited calling in the U.S. & Canada</li>
                  <li>Easy to set up and use</li>
                  <li>Toll-free and local numbers</li>
                  <li>Add remote workers and devices</li>
                  <li>Connect to multiple offices in one system</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Answering rules and auto-reception</li>
                  <li>Voicemail</li>
                  <li>Call logs</li>
                  <li>Dial-by-name directory</li>
                  <li>Mobile Apps</li>
                  <li>Call screening and forwarding</li>
                  <li>
                    Box, Dropbox, Google Drive, and Microsoft Outlook
                    integrations
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default HostedVoipConnectivity

export const HostedVoipConnectivityQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
